<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
  >
    <FormFieldWrap
      class="bf-checkbox"
      :class="{ checked : isChecked }"
      v-bind="$props"
      label-position="after"
      :error="errors[0]"
      @click="handleLabelClick"
    >
      <input
        v-model="isChecked"
        class="bf-checkbox"
        type="checkbox"
        v-bind="$attrs"
      >
      <SvgIcon
        v-if="isChecked"
        name="check"
        class="check-icon"
      />
    </FormFieldWrap>
  </ValidationProvider>
</template>
<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'
export default {
  name: 'BfCheckbox',
  components: {
    FormFieldWrap
  },
  inheritAttrs: false,
  props: {
    ...FormFieldWrap.props,
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: this.$props.value || this.$attrs.value || false
    }
  },
  computed: {
    rules() {
      return this.required ? { required: { allowFalse: false } } : ''
    }
  },
  watch: {
    isChecked(newValue) {
      // Checkboxes behave oddly with passing vue models around. To simplify things,
      // set a model directly in this component, and watch its value to emit an input event,
      // which the parent will listen for automatically if `v-model` has been set.
      this.$emit('input', newValue)
    }
  },
  methods: {
    // When a label is clicked this will update the checkbox value.
    handleLabelClick() {
      this.isChecked = !this.isChecked
    }
  }
}
</script>
<style lang="scss">

.bf-checkbox.form-field {
  margin-bottom: 1rem;

  .check-icon {
    position: absolute;
    fill: $white-matter;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
  }

  .field-wrap {
    // modify padding to account for borders.
    padding: calc(1rem - 1px);
    padding-top: calc(.875rem - 1px);
    padding-bottom: calc(.875rem - 1px);
    border: 1px solid $cortex;
    border-radius: 2px;
    background-color: $white-matter;
    display: flex;

    &:hover {
      background-color: $dendrite;
    }

    input {
      flex-grow: 0;
      flex-shrink: 0;
      appearance: none; // turn off native browser styles
      position: relative;
      width: 1.25rem;
      height: 1.25rem;
      margin: 0;
      margin-right: .5rem;
      border: 1px solid $hillcock;
      border-radius: 1px;
      outline: none;
      background: $white-matter;
      cursor: pointer;

      &[disabled] {
        cursor: not-allowed;
      }

      &:checked {
        background-color: $dopamine;
        border-color: transparent;
      }

      &:active,
      &:focus,
      &:hover {
        border-color: $dopamine;
      }
    }

    .icon-wrapper {
      display: inline-block;
      position: initial;

      svg {
        margin-left: -1rem;
      }
    }

    label {
      flex-grow: 1;
      color: $black;
      @include text-style('interface', 'medium', 'medium');
      user-select: none; // disables text highlighting
    }
  }

  &.checked .field-wrap {
    padding: calc(1rem - 2px);
    padding-top: calc(.875rem - 2px);
    padding-bottom: calc(.875rem - 2px);
    border: 2px solid $black;

    &:hover {
      background-color: $white-matter;
    }
  }

  &.has-error {
    .icon-wrapper {
      display: none;
    }

    & + .errors {
      margin-top: .25rem;
    }
  }
}
</style>
